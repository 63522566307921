$(document).ready(function(){
	$('#slider').nivoSlider({
		effect: 'sliceDown',               // Specify sets like: 'fold,fade,sliceDown'
		animSpeed: 500,                 // Slide transition speed
		pauseTime: 5000,                // How long each slide will show
		//prevText: 'Wstecz',               // Prev directionNav text
		//nextText: 'Dalej',               // Next directionNav text
		controlNav: false,
		directionNav: true,
	});
});