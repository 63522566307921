(function($) {
	$.fn.footSlider=function() {
		var $this=$(this);
		$(document).ready(function() {
			var $list=$this.find('li');

			//var isize=$(this).width()/5;
			var isize=400;
			var width=$list.size()*isize;

			function updateLayout() {
				$list.each(function(index) {
					var left=$(this).position().left;

					$(this).css({left: left-2});

					/*$(this).stop(true,true).animate({left: left-isize*1}, 1000, function() {
						var left=$(this).position().left;*/

						if (left<-isize) {
							$(this).css({left: left+width});
						}
					//});
				});
			}

			$list.each(function(index) {
				$(this).css({
					left: index*isize,
					width: isize
				});
			});

			window.setInterval(updateLayout, 80);
		});
	};
})(jQuery);